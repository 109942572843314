import {Controller} from "@hotwired/stimulus"
import * as MastodonAPI from '../../mastodon-api'
import {template} from "lodash"

export default class extends Controller {
    static values = {handle: String, person: Object}
    static targets = ['accountInfo', 'note', 'title', 'modalButton',
        'description', 'avatar', 'followers', 'mastodonUrl', 'accountTemplate', 'accountFieldTemplate']

    connect() {
        if (this.hasHandleValue) {
            MastodonAPI.getAccount(this.handleValue).then(json => {
                this.personValue = json;
                this.personValue.handle = this.handleValue;
                if(json.note !== '') {
                    this.descriptionTarget.innerHTML = json.note;
                    this.descriptionTarget.parentElement.classList.remove('hidden');
                }
                this.avatarTarget.src = json.avatar;
                this.avatarTarget.classList.add('animate__animated', 'animate__bounceIn');
                this.mastodonUrlTarget.href = json.url;
            }).catch(error => console.log(error));
        }
    }
}