import {Controller} from "@hotwired/stimulus"
import * as MastodonAPI from '../../mastodon-api'
import Rails from '@rails/ujs'
import {phone} from 'phone';

const colorsRegExp = /blue|green|gray|red/g;
export default class extends Controller {
    static values = {url: String, account: Object, handle: String, accountType: {type: String, default: 'people'}}
    static targets = ['mastodonHandle', 'handleError', 'step0', 'step01', 'step1', 'step2', 'stepper0', 'stepper1', 'stepper2',
        'subscribeForm', 'formTitle', 'accountIndividual', 'accountOrganization',
        'personAvatar', 'personName', 'personHandle', 'personKeywords', 'personHashtags',
        'personEmail', 'personPhoneNumber', 'personFirstName', 'backdrop',
        'personLastName', 'personCountry', 'personRegion', 'personAddress',
        'personTopic', 'personJobTitle', 'personOrganisation', 'personOrganisationType',
        'personWebsiteUrl', 'personBlogUrl', 'topicsController', 'otherUrl']

    connect() {
        this.accountValue = {};

        // show route
        if (this.handleValue) {
            this.mastodonHandleTarget.value = this.handleValue;
            this.check();
        } else {
            this.backdropTarget.classList.add('hidden');
        }
    }

    async check() {
        let mastodonHandleRGEX = /@?\b([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})\b/gmi;
        let handle = this.mastodonHandleTarget.value;
        if (!mastodonHandleRGEX.test(this.mastodonHandleTarget.value)) {
            this.resetForm();
            this.mastodonHandleTarget.value = handle;
            this.showError('Please Enter a valid mastodon Handle');
        } else {
            this.handleErrorTarget.classList.add('hidden');
            this.mastodonHandleTarget.classList.remove('border-red-500');
            this.mastodonHandleTarget.disabled = true;
            if (this.mastodonHandleTarget.value[0] !== '@')
                this.mastodonHandleTarget.value = `@${this.mastodonHandleTarget.value}`;
            MastodonAPI.getAccount(this.mastodonHandleTarget.value).then(async account => {
                fetch(`/people/show.json?handle=${account.handle}`).then(response => response.json())
                    .then((data) => {
                        if (data.success) {
                            this.showError('Handle already exists');
                        } else {
                            this.accountValue = account;
                            this.personHashtagsTarget.setAttribute('data-hashtags-field-url-value', account.handle.split('@').slice(-1));
                            this.personAvatarTarget.setAttribute('src', this.accountValue.avatar);
                            this.personNameTarget.innerHTML = this.accountValue.display_name;
                            this.personHandleTarget.innerHTML = this.accountValue.handle;
                            if (this.accountTypeValue === 'people' || this.accountTypeValue === 'organizations') {
                                this.formTitleTarget.querySelector('a').setAttribute('href', account.url);
                                this.step1();
                            } else {
                                this.formTitleTarget.innerHTML = `Add <a href="${account.url}" target="_blank">${account.handle}</a>`;
                                this.step01();
                            }
                        }
                    });
            }).catch(error => {
                this.resetForm();
                this.mastodonHandleTarget.value = handle;
                this.showError('Mastodon Handle not found');
            });
        }
    }

    resetForm() {
        this.accountTypeValue = null;
        history.replaceState({}, '', `/people/new`);
        this.hideError();
        this.emptyFields();
        this.step0Target.classList.remove('hidden');
        this.step01Target.classList.add('hidden');
        this.step1Target.classList.add('hidden');
        this.step2Target.classList.add('hidden');
        this.accountIndividualTarget.checked = true;
        this.accountOrganizationTarget.checked = false;
        this.formTitleTarget.innerHTML = 'Suggest New Listing';
        this.switchStepper(this.stepper0Target, 'blue');
        this.switchStepper(this.stepper1Target, 'gray');
        this.switchStepper(this.stepper2Target, 'gray');
        this.backdropTarget.classList.add('hidden');
    }

    emptyFields() {
        this.accountValue = {};
        let topicsController = this.application.getControllerForElementAndIdentifier(
            this.topicsControllerTarget, 'topics'
        );
        if (topicsController)
            topicsController.clearSearch();
        this.personTopicTarget.value = '';
        this.personAvatarTarget.setAttribute('src', '');
        this.personNameTarget.innerHTML = '';
        this.personHandleTarget.innerHTML = '';
        this.mastodonHandleTarget.value = '';
        this.personEmailTarget.value = '';
        this.personPhoneNumberTarget.value = '';
        this.personFirstNameTarget.value = '';
        this.personLastNameTarget.value = '';
        this.personCountryTarget.value = '';
        if (!!this.personCountryTarget.tomselect) {
            this.personCountryTarget.tomselect.clear();
            this.personCountryTarget.tomselect.clearOptions();
        }
        this.personRegionTarget.value = '';
        if (!!this.personRegionTarget.tomselect) {
            this.personRegionTarget.tomselect.clear();
            this.personRegionTarget.tomselect.clearOptions();
        }
        this.personJobTitleTarget.value = '';
        this.personOrganisationTarget.value = '';
        this.personOrganisationTypeTarget.value = '';
        this.personWebsiteUrlTarget.value = '';
        this.personBlogUrlTarget.value = '';
    }

    showError(message) {
        this.handleErrorTarget.classList.remove('hidden');
        this.mastodonHandleTarget.classList.add('border-red-500');
        this.handleErrorTarget.innerHTML = message;
        this.mastodonHandleTarget.disabled = false;
    }

    hideError() {
        this.handleErrorTarget.classList.add('hidden');
        this.mastodonHandleTarget.classList.remove('border-red-500');
        this.handleErrorTarget.innerHTML = '';
        this.mastodonHandleTarget.disabled = false;
    }

    step01() {
        if (!this.accountValue.handle) {
            this.resetForm();
            return;
        }
        this.step0Target.classList.add('hidden');
        this.step1Target.classList.add('hidden');
        this.step2Target.classList.add('hidden');
        this.step01Target.classList.remove('hidden');
        this.switchStepper(this.stepper0Target, 'blue');
        this.switchStepper(this.stepper1Target, 'gray');
        this.switchStepper(this.stepper2Target, 'gray');
        this.backdropTarget.classList.add('hidden');
    }

    accountType() {
        if (this.accountOrganizationTarget.checked) {
            this.accountTypeValue = 'organizations';
        } else {
            this.accountTypeValue = 'people';
        }
        Turbo.visit(`/${this.accountTypeValue}/${this.accountValue.handle}`)
    }

    step1() {
        if (!this.accountValue.handle) {
            this.resetForm();
            return;
        }
        this.personAvatarTarget.setAttribute('src', this.accountValue.avatar);
        this.personNameTarget.innerHTML = this.accountValue.display_name;
        this.personHandleTarget.innerHTML = this.accountValue.handle;
        this.step0Target.classList.add('hidden');
        this.step01Target.classList.add('hidden');
        this.step2Target.classList.add('hidden');
        this.step1Target.classList.remove('hidden');
        this.switchStepper(this.stepper0Target, 'green');
        this.switchStepper(this.stepper1Target, 'blue');
        this.switchStepper(this.stepper2Target, 'gray');
        this.backdropTarget.classList.add('hidden');

    }

    step2() {
        if (!MastodonAPI.isLoggedIn()) {
            let loginButton = document.querySelector('#login-button');
            loginButton.click();
            return;
        }
        if (!this.accountValue.handle) {
            this.resetForm();
            return;
        }
        if (this.accountTypeValue === 'people' && !this.checkFieldValidity([
            this.personEmailTarget, this.personPhoneNumberTarget, this.personFirstNameTarget,
            this.personLastNameTarget, this.personCountryTarget, this.personRegionTarget
        ]))
            return;

        if (this.accountTypeValue === 'organizations' && !this.checkFieldValidity([
            this.personOrganisationTarget, this.personOrganisationTypeTarget,
            this.personEmailTarget, this.personPhoneNumberTarget,
            this.personAddressTarget, this.personCountryTarget, this.personRegionTarget,
            this.personFirstNameTarget, this.personLastNameTarget, this.personJobTitleTarget
        ]))
            return;
        this.step0Target.classList.add('hidden');
        this.step01Target.classList.add('hidden');
        this.step1Target.classList.add('hidden');
        this.step2Target.classList.remove('hidden');
        this.switchStepper(this.stepper0Target, 'green');
        this.switchStepper(this.stepper1Target, 'green');
        this.switchStepper(this.stepper2Target, 'blue');
    }

    submit(event) {
        if (!this.accountValue.handle) {
            this.resetForm();
            return;
        }

        if (this.accountTypeValue === 'people' && !this.checkFieldValidity([
            this.personJobTitleTarget, this.personOrganisationTarget,
            this.personOrganisationTypeTarget
        ]))
            return;

        let formData = new FormData();
        formData.set('mastodon_handle', this.accountValue.handle);
        formData.set('name', this.accountValue.display_name);
        formData.set('mastodon_url', this.accountValue.url);
        formData.set('email', this.personEmailTarget.value);
        formData.set('phone_number', this.personPhoneNumberTarget.value);
        formData.set('first_name', this.personFirstNameTarget.value);
        formData.set('last_name', this.personLastNameTarget.value);
        formData.set('region_id', this.personRegionTarget.value);
        formData.set('job_title', this.personJobTitleTarget.value);
        formData.set('organisation', this.personOrganisationTarget.value);
        formData.set('organisation_type', this.personOrganisationTypeTarget.value);
        formData.set('keywords', this.personKeywordsTarget.value);
        formData.set('hashtags', this.personHashtagsTarget.value);
        formData.set('website_url', this.personWebsiteUrlTarget.value);
        formData.set('blog_url', this.personBlogUrlTarget.value);
        formData.set('account_type', this.accountTypeValue);
        if(this.hasJournalistAddressTarget)
            formData.set('address', this.personAddressTarget.value);

        for (let i = 0; i < this.otherUrlTargets.length; i++) {
            if (this.otherUrlTargets[i].value === '') continue;
            formData.append('urls[]', this.otherUrlTargets[i].value);
        }
        if (this.personTopicTarget.value.length > 0) {
            let topics_list = this.personTopicTarget.value.split(',');
            for (let i = 0; i < topics_list.length; i++) {
                formData.append('topic_ids[]', topics_list[i]);
            }
        }
        event.target.getElementsByTagName('svg')[0].classList.remove('invisible');
        Rails.ajax({
            url: this.urlValue,
            type: 'post',
            data: formData,
            complete: (response) => {
                window.location.reload(true);
            },
        });
    }

    switchStepper(element, color) {
        element.className = element.className.replaceAll(colorsRegExp, color);
        let span = element.getElementsByTagName('span')[0]
        if (!span) return;
        span.className = span.className.replaceAll(colorsRegExp, color);
    }

    checkFieldValidity(fields) {
        for (const item in fields) {
            let field = fields[item];
            if (field.type === 'tel') {
                let validPhone = phone(field.value);
                if (!validPhone.isValid && field.value !== '') {
                    field.setCustomValidity('"' + field.value + '" is not a valid phone number.');
                    field.reportValidity();
                    return false;
                } else {
                    field.value = validPhone.phoneNumber;
                }
            } else {
                if (!field.checkValidity()) {
                    field.reportValidity();
                    return false;
                }
            }
        }
        return true;
    }
}